[data-checkbox-label] {
  display: none;
}

[data-checkbox-value] {
  display: block;
}

[data-checkbox-value]::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid;
  border-color: hsla(0, 0%, 20%, 0.1);
  border-radius: 50%;
}

[data-checkbox-value]::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: white;
  border: 1px solid;
  border-color: hsla(0, 0%, 8%, 0.5);
  opacity: 0;
}
