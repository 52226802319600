[data-palmerhq-radio-group] {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  padding: 0;
  margin: 0;
  list-style: none;
}

[data-palmerhq-radio-group]:focus {
  outline: none;
}

[data-palmerhq-radio] {
  display: block;
  position: relative;
  padding-left: 2rem;
  cursor: default;
  outline: none;
  font-size: 1.4rem;
  line-height: 1;
  cursor: pointer;
}

[data-palmerhq-radio]::before,
[data-palmerhq-radio]::after {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: "";
}

[data-palmerhq-radio]::before {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid hsla(0, 0%, 20%, 0.1);
  border-radius: 100%;
  background-color: #fff;
}

[data-palmerhq-radio][aria-checked="true"]::before {
  background: #fff;
}

[data-palmerhq-radio][aria-checked="true"]::after {
  width: 6px;
  height: 6px;
  background: #343434;
  left: 3px;
  border-radius: 50%;
}

[data-palmerhq-radio][aria-checked="mixed"]:active::before,
[data-palmerhq-radio][aria-checked="true"]:active::before {
  background-color: hsla(0, 0%, 20%, 0.1);
}

[data-palmerhq-radio]:hover::before {
  border-color: hsla(0, 0%, 20%, 0.2);
}

[data-palmerhq-radio][data-palmerhq-radio-focus="true"] {
  border-color: hsla(0, 0%, 20%, 0.2);
}

/* [data-palmerhq-radio]:hover {
  background-color: hsl(216, 80%, 92%);
} */
